define("mgw/components/select-organization", ["exports", "mgw/components/popover-base"], function (_exports, _popoverBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    intl: Ember.inject.service(),
    users: Ember.computed.alias('data.users'),
    organizations: Ember.computed.alias('data.organizations'),
    selectedOrg: null,
    addOrganizationTranslation: 'addOrganization',
    createNewOrganizationTranslation: 'createNewOrganization',
    addOrganizationDescriptionTranslation: 'addOrganization__description',
    items: [],
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
    },
    setup: function setup() {
      var orgs = this.get('organizations') || [];
      var users = this.get('users') || [];
      var userOrgNames = new Set();
      orgs.forEach(function (org) {
        userOrgNames.add(org.get('name'));
      });
      var freeTextOrgs = new Set();
      users.forEach(function (user) {
        if (user.get('organization.length') && !userOrgNames.has(user.get('organization'))) freeTextOrgs.add(user.get('organization'));
      });
      var items = orgs.map(function (org) {
        return Ember.Object.create({
          name: org.get('name'),
          record: org
        });
      }).concact(Array.from(freeTextOrgs).map(function (freeTextOrg) {
        return Ember.Object.create({
          name: freeTextOrg,
          record: {
            id: freeTextOrg,
            name: freeTextOrg
          }
        });
      })).sort(function (a, b) {
        var aType = a.record.type ? 0 : 1;
        var bType = b.record.type ? 0 : 1;
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase()) || aType - bType;
      });
      this.set('items', items);
    },
    defaultResult: Ember.computed('items.[]', function () {
      return this.queryItems();
    }),
    queryItems: function queryItems(_term) {
      var _this$get;

      var term = _term || '';
      var self = this;
      var results = [];
      var foundEqual = false;
      (_this$get = this.get('items')) === null || _this$get === void 0 ? void 0 : _this$get.forEach(function (item) {
        if (term === item.get('record.name')) foundEqual = true;
        var name = item.get('record.name') || item.get('record') || '';
        var matchingSearch = name.toLowerCase().indexOf(term.toLowerCase()) !== -1;

        if (matchingSearch) {
          results.pushObject(item);
        }
      }); // If we have not found a match, we allow creating new record and does not include a comma 
      // if we are creating a checklistOption

      if (!foundEqual && (term === null || term === void 0 ? void 0 : term.length)) {
        // Add new option
        var addNewOption = Ember.Object.create({
          id: -1,
          name: self.intl.t(this.addOrganizationTranslation) + ': "' + term + '"',
          record: term,
          added: true
        });
        results.pushObject(addNewOption);
      }

      return results;
    },
    actions: {
      submit: function submit() {
        //this.get('delegate').send(this.get('callback'), this.get('selectedRoleId'));
        this.send('close');
      },
      cancel: function cancel() {
        this.send('close');
      },
      onChange: function onChange(organization) {
        if (!organization) return;

        if (organization.type == 'userOrganization') {
          console.log('here org');
        } else {
          console.log('here free text');
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});