define("mgw/models/customer-user-profile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', {
      readOnly: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    lastModifiedAt: _emberData.default.attr('date', {
      readOnly: true
    }),
    lastModifiedBy: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    user: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    customer: _emberData.default.belongsTo('customer', {
      inverse: null
    })
  });

  _exports.default = _default;
});