define("mgw/components/dashboard-project-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    // input
    project: null,
    editModeActive: false,
    widgets: Ember.computed.alias('project.dashboardWidgets'),
    sortByOrder: ['order'],
    orderedWidgets: Ember.computed.sort('widgets', 'sortByOrder'),
    empty: Ember.computed.empty('widgets'),
    numWidgets: Ember.computed('orderedWidgets.[]', function () {
      if (this.get('empty')) {
        return 0;
      } else {
        return this.get('orderedWidgets').length;
      }
    }),
    reorderWidgets: function reorderWidgets() {
      var index = 0;
      this.get('orderedWidgets').forEach(function (widget) {
        if (widget.order !== index) {
          widget.set('order', index);
          widget.save();
        }

        index++;
      });
    },
    actions: {
      addWidget: function addWidget(widgetType) {
        var size = [1, 1];
        if (widgetType === 'workflow-chart') size[1] = 2;
        var newWidget = this.store.createRecord('dashboard-widget', {
          typeName: widgetType,
          order: this.get('numWidgets'),
          size: size,
          dataIds: null,
          project: this.get('project')
        });
        newWidget.save();
      },
      deleteWidget: function deleteWidget(widget) {
        var _this = this;

        if (!widget) return;
        widget.destroyRecord().then(function () {
          if (_this.get('widgets') && _this.get('widgets').length === 0) {
            _this.reloadWidgets();
          } else {
            _this.reorderWidgets();
          }
        });
      },
      moveWidget: function moveWidget(widget, step) {
        if (!widget) return;
        var left = step < 0;
        var oldOrder = widget.order;
        var widgetIdx = this.get('orderedWidgets').indexOf(widget); // No movement can be done if already at the edge

        if (widgetIdx <= 0 && left || widgetIdx >= this.get('orderedWidgets').length - 1 && !left) return;
        var swapWidget = this.get('orderedWidgets').objectAt(left ? widgetIdx - 1 : widgetIdx + 1);
        var newOrder = swapWidget.order;
        swapWidget.set('order', oldOrder);
        swapWidget.save();
        widget.set('order', newOrder);
        widget.save();
      },
      deleteCard: function deleteCard() {
        var _this2 = this;

        var promises = [];
        this.get('widgets').forEach(function (widget) {
          return promises.push(widget.destroyRecord());
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          return _this2.reloadWidgets();
        });
      },
      changeEditingWidget: function changeEditingWidget(bool) {
        this.changeEditingWidget(bool);
      }
    }
  });

  _exports.default = _default;
});