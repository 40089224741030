define("mgw/controllers/project-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var colorPalette = ['#A23EBB', '#BBA23E', '#3E55BB', '#BF4097', '#BF6640', '#BCC247', '#BF4060', '#3EBBBB', '#82342B', '#2A7E4A', '#502A7E', '#867574', '#A4DE9C', '#A3AEE0', '#305E91', '#6D7F3D', '#C0866D', '#ECC6D7', '#C14644', '#7BBB3E'];
  var libraryTypesMap = {
    checklists: 'checklistId',
    workflows: 'workflowId',
    folders: 'folderId',
    groups: 'groupId',
    folderLabels: 'folderLabelId',
    labelGroups: 'labelGroupId',
    reportTemplates: 'reportTemplateId',
    widgets: 'widgetId',
    taktTrains: 'taktTrainId'
  };

  var FindResourceIndexInContent = function FindResourceIndexInContent(resource, content) {
    if (!content || !resource) return -1;
    var index = content.findIndex(function (contentItem) {
      return contentItem.sourceProjectId === resource.sourceProjectId && contentItem.key === resource.key && contentItem.value === resource.value && contentItem.renameTo === resource.renameTo;
    });
    return index;
  };

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    popoverservice: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedTemplate: null,
    selectedLibraryId: null,
    selectedLibrarySection: null,
    libraryContent: null,
    errorLoadingLibrary: null,
    // Contains all items selected from the library, ready to be applied to the template
    selectedResources: [],
    // Template to rename/delete
    templateToEdit: null,
    confirmDelete: false,
    // Holds the new template/library created, so we can assign the name before saving it
    newTemplateOrLibrary: null,
    editRecordTitle: Ember.computed('newTemplateOrLibrary', function () {
      return this.intl.t(this.get('newTemplateOrLibrary.isLibrary') ? 'newLibrary' : 'newTemplate');
    }),
    templates: Ember.computed('model.templates', 'currentUser.activeCustomer', function () {
      var _this$get;

      return ((_this$get = this.get('model.templates')) === null || _this$get === void 0 ? void 0 : _this$get.filterBy('customer.id', this.get('currentUser.activeCustomer.id')).sortBy('name')) || [];
    }),
    libraries: Ember.computed('model.libraries', 'currentUser.activeCustomer', function () {
      var _this$get2;

      return ((_this$get2 = this.get('model.libraries')) === null || _this$get2 === void 0 ? void 0 : _this$get2.filterBy('customer.id', this.get('currentUser.activeCustomer.id')).rejectBy('deletedAt').sortBy('name')) || [];
    }),
    templatesObserver: Ember.observer('templates.[]', function () {
      this.set('selectedTemplate', this.get('templates.length') ? this.get('templates.firstObject') : null);
    }),
    librariesObserver: Ember.observer('libraries.[]', function () {
      this.set('selectedLibraryId', this.get('libraries.length') ? this.get('libraries.firstObject.id') : null);
    }),
    libraryColorMap: Ember.computed('libraries', function () {
      var colorMap = {};
      if (!this.get('libraries.length')) return colorMap;
      var i = 0;
      this.get('libraries').forEach(function (library) {
        colorMap[library.get('id')] = colorPalette[i % colorPalette.length];
        i++;
      });
      return colorMap;
    }),
    // This observer loads the content of a selected library and assign it to 'libraryContent'
    inputLibraryObserver: Ember.observer('selectedLibraryId', function () {
      var _this = this;

      this.set('libraryContent', null);
      this.set('errorLoadingLibrary', null);

      if (this.get('selectedLibraryId')) {
        this.set('loadingLibrary', true);
        this.store.query('projectContent', {
          filter: {
            project: this.get('selectedLibraryId') + ""
          }
        }).then(function (contents) {
          _this.set('libraryContent', _this.processLibraryContent(contents));
        }).catch(function (err) {
          _this.set('errorLoadingLibrary', err);

          console.warn("Error getting content from library " + _this.get('selectedLibraryId') + " , error: " + err.message);
        }).finally(function () {
          _this.set('loadingLibrary', false);
        });
      }
    }),
    // This function is necessary to transform the structure of library resources into the same structure
    // used on templates. It is just a basic mapping
    processLibraryContent: function processLibraryContent(contents) {
      if (!(contents === null || contents === void 0 ? void 0 : contents.length)) return null;
      var processedResources = contents.map(function (resource) {
        return {
          sourceProjectId: Number(resource.get('project.id')),
          key: libraryTypesMap[resource.get('entityType')] || resource.get('entityType'),
          value: resource.get('record'),
          renameTo: resource.get('name')
        };
      });
      processedResources.push({
        sourceProjectId: Number(this.get('selectedLibraryId')),
        key: 'configs',
        isEverything: true
      });
      return processedResources;
    },
    init: function init() {
      this.get('templates');
      this.get('libraries');

      this._super();
    },
    actions: {
      selectTemplate: function selectTemplate(template) {
        this.set('selectedTemplate', template);
        this.set('selectedResources', []);
      },
      selectLibrary: function selectLibrary(library) {
        if (library.get('id') === this.get('selectedLibraryId')) return;
        this.set('selectedLibraryId', library.get('id'));
        this.set('selectedResources', []);
      },
      createTemplate: function createTemplate() {
        var newTemplate = this.store.createRecord('projectTemplate', {
          content: [],
          customer: this.get('currentUser.activeCustomer')
        });
        this.set('newTemplateOrLibrary', newTemplate);
      },
      createLibrary: function createLibrary() {
        var newLibrary = this.store.createRecord('project', {
          isLibrary: true,
          customer: this.get('currentUser.activeCustomer')
        });
        this.set('newTemplateOrLibrary', newLibrary);
      },
      acceptedRecordName: function acceptedRecordName() {
        var _this2 = this;

        this.get('newTemplateOrLibrary').save().then(function () {
          var newRecord = _this2.get('newTemplateOrLibrary');

          _this2.set('newTemplateOrLibrary', null);

          if (newRecord === null || newRecord === void 0 ? void 0 : newRecord.get('projectUri')) {
            _this2.send('reload');

            _this2.send('showGoToLibrary', newRecord);
          }
        }).catch(function (response) {
          _this2.set('newTemplateOrLibrary', null);

          console.log("Unable to Updated record reason: ".concat(response.errors[0].detail));
        });
      },
      canceledRecordName: function canceledRecordName() {
        this.get('newTemplateOrLibrary').rollbackAttributes();
        this.get('newTemplateOrLibrary').unloadRecord();
        this.set('newTemplateOrLibrary', null);
      },
      editTemplate: function editTemplate(template) {
        this.set('templateToEdit', template);
        return false;
      },
      acceptedEditedTemplate: function acceptedEditedTemplate() {
        var _this3 = this;

        this.get('templateToEdit').save().then(function () {
          _this3.set('templateToEdit', null);
        }).catch(function (response) {
          _this3.set('templateToEdit', null);

          console.log("Unable to rename/delete template record, reason: ".concat(response.errors[0].detail));
        });
      },
      deleteTemplate: function deleteTemplate() {
        this.set('confirmDelete', true);
      },
      cancelDeleteTemplate: function cancelDeleteTemplate() {
        this.set('confirmDelete', false);
      },
      confirmDeleteTemplate: function confirmDeleteTemplate() {
        var _this$get3,
            _this4 = this;

        (_this$get3 = this.get('templateToEdit')) === null || _this$get3 === void 0 ? void 0 : _this$get3.destroyRecord().then(function () {
          _this4.set('templateToEdit', null);

          _this4.set('confirmDelete', false);
        });
      },
      canceledEditTemplate: function canceledEditTemplate() {
        this.get('templateToEdit').rollbackAttributes();
        this.set('templateToEdit', null);
      },
      showGoToLibrary: function showGoToLibrary(libraryRecord) {
        this.get('popoverservice').openPopover({
          name: 'goto-library',
          data: libraryRecord,
          delegate: this,
          popoverClass: 'popover--floating'
        });
      },
      applySelection: function applySelection() {
        var _this5 = this;

        if (!this.get('selectedTemplate')) return;
        var templateContent = this.get('selectedTemplate.content');
        var templateUpdated = false;
        this.get('selectedResources').forEach(function (resource) {
          var index = FindResourceIndexInContent(resource, templateContent);

          if (index < 0) {
            templateContent.push(resource);
            templateUpdated = true;
          }
        });

        if (templateUpdated) {
          this.get('selectedTemplate').set('content', templateContent);
          this.get('selectedTemplate').save().then(function () {
            _this5.set('selectedResources', []);
          });
        }

        return false;
      },
      removeResourceFromTemplate: function removeResourceFromTemplate(resource) {
        if (!this.get('selectedTemplate') || !resource) return;
        var templateContent = this.get('selectedTemplate.content');
        var index = FindResourceIndexInContent(resource, templateContent);

        if (index >= 0) {
          templateContent.splice(index, 1);
          this.get('selectedTemplate').set('content', templateContent);
          this.get('selectedTemplate').save();
        }
      },
      selectedTemplateResource: function selectedTemplateResource(resource, section) {
        this.set('selectedResources', []);
        if (resource.sourceProjectId !== this.get('selectedLibraryId')) this.set('selectedLibraryId', "".concat(resource.sourceProjectId));
        this.set('selectedLibrarySection', section);
      },
      changeCustomer: function changeCustomer(customer) {
        this.get('currentUser').changeActiveCustomer(customer);
        return false;
      }
    }
  });

  _exports.default = _default;
});