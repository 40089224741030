define("mgw/templates/components/navigation-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qwC81nTc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6,[\"mobile\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[\"page-icon \",[34,4]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"page-title\"],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-grow\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n  \"],[10,\"menu\"],[15,0,[31,[\"global-logout  \",[30,[36,5],[[30,[36,10],[[35,9],[35,8]],null],\"global-logout--cordova\"],null]]]],[12],[2,\"\\n    \"],[10,\"nav\"],[14,0,\"flex gap-15 align-items-center\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"route\",\"text\",\"icon\",\"onSelect\"],[\"index\",[30,[36,1],[\"openProject\"],null],\"icon--logo\",[30,[36,0],[[32,0],\"goToRoute\"],null]]]]],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"route\",\"text\",\"icon\",\"options\",\"onSelect\"],[\"dashboard\",[30,[36,1],[\"enterpriseTools\"],null],\"icon--configs\",[35,11],[30,[36,0],[[32,0],\"goToRoute\"],null]]]]],[2,\"\\n\"],[6,[37,7],[[35,6,[\"iOS\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"route\",\"text\",\"icon\",\"onSelect\"],[\"help\",[30,[36,1],[\"help\"],null],\"icon--help--orange\",[30,[36,0],[[32,0],\"goToRoute\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[1,[30,[36,2],null,[[\"text\",\"icon\",\"options\",\"onSelect\"],[[35,13],\"icon--user\",[35,12],[30,[36,0],[[32,0],\"goToRoute\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"navigation-btn\",\"title\",\"icon\",\"if\",\"deviceservice\",\"unless\",\"andoid\",\"ios\",\"or\",\"enterpriseOptions\",\"accountOptions\",\"accountTitle\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/navigation-bar.hbs"
    }
  });

  _exports.default = _default;
});