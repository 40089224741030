define("mgw/components/date-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    deviceservice: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Input
    value: null,
    minDate: null,
    maxDate: null,
    format: 'YYYY-MM-DD',
    classNames: ['input-date-container'],
    _value: null,
    isInputValueValid: Ember.computed('value', 'format', function () {
      return this.get('value') && window.moment(this.get('value'), this.get('format'), true).isValid();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('_value', this.get('isInputValueValid') ? window.moment(this.get('value')).format(this.get('format')) : null);
      if (this.minDate) this.set('minValue', window.moment(this.get('minDate')).format(this.get('format')));
      if (this.maxDate) this.set('maxValue', window.moment(this.get('maxDate')).format(this.get('format')));
    },
    actions: {
      onChange: function onChange(value) {
        this.set('_value', value.length ? value : null);
        this.submit(this.get('_value'));
        return false;
      }
    }
  });

  _exports.default = _default;
});