define("mgw/controllers/project-widgets", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    dashboardOrderOptions: (0, _emberLocalStorage.storageFor)('dashboard-order'),
    editModeActive: false,
    editingWidget: null,
    searchStr: '',
    projectSelectionState: null,
    selectingProject: false,
    orderSelectorClassNames: ['width-100'],
    orderOptions: Ember.computed('intl.locale', function () {
      return [{
        value: "name",
        name: this.intl.t('name')
      }, {
        value: "createdAt",
        name: this.intl.t('created')
      }, {
        value: "numUsers",
        name: this.intl.t('users')
      }, {
        value: "numBuildings",
        name: this.intl.t('buildings')
      }, {
        value: "numGroups",
        name: this.intl.t('groups')
      }, {
        value: "numDocuments",
        name: this.intl.t('documents')
      }, {
        value: "numFolders",
        name: this.intl.t('folders')
      }, {
        value: "numTemplates",
        name: this.intl.t('workflows')
      }, {
        value: "numActualTopics",
        name: this.intl.t('topics')
      }];
    }),
    orderSelectionState: Ember.computed.alias('dashboardOrderOptions.propertyName'),
    ascendingSort: Ember.computed.alias('dashboardOrderOptions.ascending'),
    projects: Ember.computed('model.projects', 'currentUser.activeCustomer', function () {
      var _this$get;

      return ((_this$get = this.get('model.projects')) === null || _this$get === void 0 ? void 0 : _this$get.filterBy('customer.id', this.get('currentUser.activeCustomer.id'))) || [];
    }),
    stats: Ember.computed('model.stats', 'currentUser.activeCustomer', function () {
      var _this$get2;

      return ((_this$get2 = this.get('model.stats')) === null || _this$get2 === void 0 ? void 0 : _this$get2.filterBy('project.customer.id', this.get('currentUser.activeCustomer.id'))) || [];
    }),
    widgets: Ember.computed('model.widgets', 'currentUser.activeCustomer', function () {
      var _this$get3;

      return ((_this$get3 = this.get('model.widgets')) === null || _this$get3 === void 0 ? void 0 : _this$get3.filterBy('project.customer.id', this.get('currentUser.activeCustomer.id'))) || [];
    }),
    statsMap: Ember.computed('stats', function () {
      var sMap = {};
      this.get('stats').forEach(function (project) {
        sMap[project.id] = project;
      });
      return sMap;
    }),
    configuredProjects: Ember.computed('projects.@each.dashboardWidgets', function () {
      return this.get('projects').filter(function (project) {
        return project.dashboardWidgets !== null && project.dashboardWidgets !== undefined && project.dashboardWidgets.filter(function (widget) {
          return !widget.isDestroyed;
        }).length > 0;
      });
    }),
    configuredProjectsMap: Ember.computed('configuredProjects', function () {
      var pMap = {};
      this.get('configuredProjects').forEach(function (project) {
        pMap[project.id] = project;
      });
      return pMap;
    }),
    noConfiguredProjectsOptions: Ember.computed('projects.@each.dashboardWidgets', function () {
      return this.get('projects').filter(function (project) {
        return project.dashboardWidgets === null || project.dashboardWidgets === undefined || project.dashboardWidgets.length === 0;
      }).map(function (project) {
        return {
          value: project.id,
          name: project.name
        };
      });
    }),
    filteredProjects: Ember.computed('searchStr', 'configuredProjects', function () {
      var _this = this;

      if (!this.get('configuredProjects')) return [];
      if (this.get('searchStr').length === 0) return this.get('configuredProjects');
      return this.get('configuredProjects').filter(function (project) {
        return project.name.toLowerCase().includes(_this.get('searchStr').toLowerCase());
      });
    }),
    filteredStats: Ember.computed('filteredProjects', function () {
      var _this2 = this;

      if (!this.get('filteredProjects')) return [];
      var filteredStats = [];
      this.get('filteredProjects').forEach(function (project) {
        filteredStats.push(_this2.get('statsMap')[project.id]);
      });
      return filteredStats;
    }),
    orderedAndFilteredProjects: Ember.computed('filteredProjects', 'orderSelectionState', 'ascendingSort', function () {
      var _this3 = this;

      if (this.get('orderSelectionState') === null) return [];
      var orderedProjects = null;

      if (["name", "createdAt"].includes(this.get('orderSelectionState'))) {
        orderedProjects = this.get('filteredProjects').sortBy(this.get('orderSelectionState'));
      } else {
        var orderedStats = this.get('filteredStats').sortBy(this.get('orderSelectionState'));
        orderedProjects = [];
        orderedStats.forEach(function (projectStat) {
          orderedProjects.push(_this3.get('configuredProjectsMap')[projectStat.id]);
        });
      }

      return this.get('ascendingSort') ? orderedProjects : orderedProjects.reverse();
    }),
    actions: {
      searchChanged: function searchChanged(value) {
        this.set('searchStr', value);
      },
      changeEditMode: function changeEditMode() {
        this.toggleProperty('editModeActive');
      },
      changeEditingWidget: function changeEditingWidget(bool) {
        this.set('editingWidget', bool);
      },
      addProjectCard: function addProjectCard() {
        this.set('selectingProject', false);
        var projectId = this.get('projectSelectionState');
        var project = this.get('projects').findBy('id', projectId);

        if (project) {
          var size = [1, 1];
          var newWidget = this.store.createRecord('dashboard-widget', {
            typeName: 'generic-info',
            order: 0,
            size: size,
            project: project
          });
          newWidget.save();
        }
      },
      selectNewProject: function selectNewProject() {
        this.set('editModeActive', true);

        if (this.get('noConfiguredProjectsOptions').length > 0) {
          this.set('projectSelectionState', this.get('noConfiguredProjectsOptions')[0].value);
          this.set('selectingProject', true);
        }
      },
      cancelSelectNewProject: function cancelSelectNewProject() {
        this.set('selectingProject', false);
      },
      changeNewProjectSelection: function changeNewProjectSelection(selected) {
        this.set('projectSelectionState', selected.value);
      },
      reloadWidgets: function reloadWidgets() {
        this.send('reload');
      },
      changeProjectOrder: function changeProjectOrder(selected) {
        this.set('orderSelectionState', selected.value);
      },
      changeOrderDirection: function changeOrderDirection() {
        this.toggleProperty('ascendingSort');
      },
      changeCustomer: function changeCustomer(customer) {
        this.get('currentUser').changeActiveCustomer(customer);
        return false;
      }
    }
  });

  _exports.default = _default;
});