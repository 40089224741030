define("mgw/components/users-grid", ["exports", "ember-local-storage", "mgw/misc/utils"], function (_exports, _emberLocalStorage, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var $ = window.$;
  var Slick = window.Slick;

  var lowerCaseMismatch = function lowerCaseMismatch(itemValue, filterValue) {
    return itemValue.toLowerCase().indexOf(filterValue.toLowerCase()) == -1;
  };

  var isMatch = function isMatch(itemValue, filterValue) {
    if (itemValue == null) {
      return false;
    } else if (filterValue && typeof itemValue === "string" && lowerCaseMismatch(itemValue, filterValue)) {
      return false;
    } else if (typeof itemValue === "number" && itemValue != parseInt(filterValue)) {
      return false;
    } else if (typeof itemValue === "boolean" && (itemValue + "").indexOf(filterValue) == -1) {
      return false;
    }

    return true;
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    popoverservice: Ember.inject.service(),
    tagName: "ul",
    columnSettingsKey: 'usersGridColumns',
    columnSettingsVersion: 1,
    // input
    items: null,
    columns: [],
    cache: null,
    searchStr: '',
    searchProjectsStr: '',
    filteredColumns: Ember.computed('columns', 'searchProjectsStr', function () {
      var _this = this;

      if (!this.get('searchProjectsStr.length')) return this.get('columns');
      return this.get('columns').filter(function (column) {
        if (column.type !== 'project') return true;
        return column.name.toLowerCase().indexOf(_this.get('searchProjectsStr').toLowerCase()) > -1;
      });
    }),
    sortByCol: Ember.computed("cache", function () {
      var storedColumn = this.get('settings.' + this.columnSettingsKey + 'SortByCol');
      return storedColumn ? storedColumn : {
        field: 'id'
      };
    }),
    sortIsAsc: Ember.computed("cache", function () {
      var sortIsAsc = this.get('settings.' + this.columnSettingsKey + 'SortIsAsc');
      return sortIsAsc ? true : false;
    }),
    sortedItems: Ember.computed("items", "sortByCol", "sortIsAsc", "cache", function () {
      var _this2 = this;

      var sort = this.items.sort(function (a, b) {
        if (_this2.sortByCol.type == 'project') {
          var _a$_this2$sortByCol$f, _b$_this2$sortByCol$f;

          // Order by project privilege level
          var aValue = a[_this2.sortByCol.field] ? (_a$_this2$sortByCol$f = a[_this2.sortByCol.field]) === null || _a$_this2$sortByCol$f === void 0 ? void 0 : _a$_this2$sortByCol$f.get('privilegeLevel') : -1;
          var bValue = b[_this2.sortByCol.field] ? (_b$_this2$sortByCol$f = b[_this2.sortByCol.field]) === null || _b$_this2$sortByCol$f === void 0 ? void 0 : _b$_this2$sortByCol$f.get('privilegeLevel') : -1;

          if (aValue == bValue) {
            // If same privilege level we just sort by email
            return a.id.localeCompare(b.id);
          } else {
            return aValue - bValue;
          }
        } else {
          return a[_this2.sortByCol.field].localeCompare(b[_this2.sortByCol.field]);
        }
      });
      if (this.sortIsAsc) return sort;
      return sort.reverse();
    }),
    observeItems: Ember.observer("items", function () {
      this.refreshGrid(true);
    }),
    observeCache: Ember.observer("cache", "searchStr", function () {
      Ember.run.debounce(this, this.refreshGrid, 300);
    }),
    observeFilteredColumns: Ember.observer("searchProjectsStr", function () {
      Ember.run.debounce(this, this.refreshColumns, 300);
    }),
    dataView: null,
    grid: null,
    showModal: false,
    selectedProject: null,
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
      window.enableJqueryDrag();
    },
    defaultFormatter: function defaultFormatter(row, cell, value) {
      return value;
    },
    filterFunc: function filterFunc(item, field, projectColumn) {
      if (field !== undefined) {
        var value = projectColumn && item[field] ? this.intl.t(_utils.default.getPrivilegeLevelKeyText(item[field].get('privilegeLevel'))) : item[field];
        return isMatch(value, this.searchStr);
      } else return false;
    },
    defaultFilter: function defaultFilter(item) {
      if (!this.searchStr || !this.searchStr.length) return true;

      var _iterator = _createForOfIteratorHelper(this.columns),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var column = _step.value;

          if (this.filterFunc(item, column.field, column.type == 'project')) {
            return true;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return false;
    },
    refreshGrid: function refreshGrid() {
      var updateColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.dataView.beginUpdate();
      this.dataView.setItems(this.sortedItems.toArray());
      this.dataView.endUpdate();
      this.grid.invalidateAllRows();
      if (updateColumns) this.grid.setColumns(this.get('filteredColumns'));
      this.grid.render();
    },
    refreshColumns: function refreshColumns() {
      this.grid.setColumns(this.get('filteredColumns'));
    },
    saveColumnSettings: function saveColumnSettings() {
      this.set('settings.' + this.columnSettingsKey, this.get("grid").getColumns());
      this.set('settings.' + this.columnSettingsKey + 'Version', this.columnSettingsVersion);
    },
    setup: function setup() {
      var _this3 = this;

      var self = this;
      var options = {
        enableCellNavigation: true,
        explicitInitialization: true,
        enableColumnReorder: true,
        defaultFormatter: this.defaultFormatter,
        forceSyncScrolling: true,
        defaultColumnWidth: 200,
        enableAsyncPostRender: true,
        asyncPostRenderDelay: 0,
        fullWidthRows: true,
        forceFitColumns: false,
        rowHeight: 40
      };
      this.set("dataView", new Slick.Data.DataView());
      this.set("grid", new Slick.Grid("#".concat(this.elementId, " .slick-container"), this.dataView, this.filteredColumns, options));
      this.grid.setSortColumn(this.sortByCol.field, this.sortIsAsc);
      this.grid.onCellChange.subscribe(function (e, args) {
        _this3.dataView.updateItem(args.item.id, args.item);
      });
      this.dataView.onRowCountChanged.subscribe(function () {
        _this3.grid.updateRowCount();

        _this3.grid.render();
      });
      this.dataView.onRowsChanged.subscribe(function (e, args) {
        _this3.grid.invalidateRows(args.rows);

        _this3.grid.render();
      });
      this.grid.onColumnsResized.subscribe(function () {
        _this3.saveColumnSettings();
      });
      this.grid.onColumnsReordered.subscribe(function () {
        _this3.saveColumnSettings();
      });
      this.grid.onSort.subscribe(function (e, args) {
        _this3.set('settings.' + _this3.columnSettingsKey + 'SortByCol', args.sortCol);

        _this3.set('settings.' + _this3.columnSettingsKey + 'SortIsAsc', args.sortAsc);

        _this3.set('cache', new Date());
      });
      this.grid.onClick.subscribe(function (e, args) {
        // If we click on a privilege btn we call to the edit options
        if (e.target.classList.contains("privilege-btn") && !e.target.classList.contains("privilege-25")) {
          var elementId = $(e.target).attr("id");
          var dataView = args.grid.getData();
          var item = dataView.getItem(args.row);
          var column = args.grid.getColumns()[args.cell];
          var memberRecord = item[column.field];
          self.editPrivilegeLevel(memberRecord, elementId, args.row);
        }
      });
      var rowSelectionModel = new Slick.RowSelectionModel();
      rowSelectionModel.onSelectedRangesChanged.subscribe(function (e, args) {
        var selectedItems = [];

        if (args.length) {
          args.forEach(function (range) {
            var item = _this3.get('dataView').getItem(range.fromRow);

            if (item) selectedItems.push(item);
          });
        }

        if (_this3.didSelectItems) _this3.didSelectItems(selectedItems);
      });
      this.set('rowSelectionModel', rowSelectionModel);
      this.get('grid').setSelectionModel(rowSelectionModel);
      this.grid.init();
      this.dataView.beginUpdate();
      this.dataView.setItems(this.sortedItems.toArray());
      this.dataView.setFilter(this.defaultFilter.bind(this));
      this.dataView.setFilterArgs({
        searchStr: this.get('searchStr')
      });
      this.dataView.endUpdate();
      this.grid.resizeCanvas();
    },
    editPrivilegeLevel: function editPrivilegeLevel(record, elementId, row) {
      if (record) {
        this.get('popoverservice').openPopover({
          name: 'member-options',
          data: record,
          callback: 'selectedPrivilegeLevel',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: elementId,
          options: {
            context: row
          }
        });
      }

      return false;
    },
    willDestroyElement: function willDestroyElement() {
      window.disableJqueryDrag();
      this.grid.destroy();
      this.set("grid", null);
      this.set("dataView", null);
    },
    actions: {
      selectedPrivilegeLevel: function selectedPrivilegeLevel(record, row) {
        var _this4 = this;

        var promises = [];
        if (record.get('hasDirtyAttributes')) promises.push(record.save());
        if (record.get('user.hasDirtyAttributes')) promises.push((record.get('user').content || record.get('user')).save());

        if (promises.length) {
          Promise.all(promises).then(function () {
            _this4.get('grid').invalidateRows([row]);

            _this4.get('grid').render();
          });
        }
      }
    }
  });

  _exports.default = _default;
});