define("mgw/components/select-role", ["exports", "mgw/components/popover-base", "mgw/misc/utils"], function (_exports, _popoverBase, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    selectedRole: 10,
    roleOptions: _utils.default.getPrivilegeLevelsInfo().rejectBy('id', 25),
    actions: {
      submit: function submit() {
        this.get('delegate').send(this.get('callback'), this.get('selectedRole'));
        this.send('close');
        return false;
      },
      cancel: function cancel() {
        this.send('close');
      },
      onChange: function onChange(privilegeLevel) {
        this.set('selectedRole', privilegeLevel);
      }
    }
  });

  _exports.default = _default;
});