define("mgw/components/member-options", ["exports", "mgw/components/popover-base", "mgw/misc/utils"], function (_exports, _popoverBase, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    currentUser: Ember.inject.service(),
    ssoproviderservice: Ember.inject.service(),
    intl: Ember.inject.service(),
    today: new Date(),
    roleOptions: Ember.computed('intl', function () {
      var _this = this;

      return _utils.default.getPrivilegeLevelsInfo().rejectBy('id', 25).map(function (privilegeLevelOpt) {
        return {
          name: _this.intl.t(privilegeLevelOpt.text),
          value: privilegeLevelOpt.id,
          class: 'privilege-' + privilegeLevelOpt.id
        };
      });
    }),
    ssoProviderOptions: Ember.computed('currentUser.activeCustomer.ssoProviders', 'selectedSSOs', function () {
      var _this2 = this;

      if (!this.get('currentUser.activeCustomer.ssoProviders.length')) return [];
      return this.get('currentUser.activeCustomer.ssoProviders').map(function (providerId) {
        var providerInfo = _this2.ssoproviderservice.getProviderInfo(providerId) || {
          id: providerId,
          name: providerId
        };
        return {
          id: providerInfo.id,
          name: providerInfo.name,
          selected: (_this2.get('selectedSSOs') || []).includes(providerInfo.id)
        };
      });
    }),
    projectMember: Ember.computed.alias('data'),
    selectedRole: Ember.computed.alias('projectMember.privilegeLevel'),
    selectedSSOs: Ember.computed.alias('projectMember.ssoProviders'),
    expirationDate: Ember.computed.alias('projectMember.user.expirationDate'),
    actions: {
      close: function close() {
        this.get('delegate').send(this.get('callback'), this.projectMember, this.options.context);

        this._super();
      },
      changePrivilegeLevel: function changePrivilegeLevel(value, selected) {
        if (!selected) {
          this.set('selectedRole', value || 0);
        }

        this.send('close');
      },
      toggleSSOProvider: function toggleSSOProvider(ssoProviderId) {
        var _this$get;

        var providers = ((_this$get = this.get('selectedSSOs')) === null || _this$get === void 0 ? void 0 : _this$get.slice()) || [];
        var index = providers.indexOf(ssoProviderId);

        if (index >= 0) {
          providers.splice(index);
        } else {
          providers.push(ssoProviderId);
        }

        this.set('selectedSSOs', providers);
        return false;
      },
      expirationDateChanged: function expirationDateChanged(dateString) {
        var expDate = new Date(dateString);
        expDate.setHours(23, 59, 59);
        this.set('expirationDate', expDate);
      }
    }
  });

  _exports.default = _default;
});