define("mgw/templates/components/navigation-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xT6Q+y4W",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"button\"],[14,0,\"nav-btn\"],[15,\"onclick\",[30,[36,9],[[35,8],[30,[36,0],[[32,0],\"onClick\",[35,7]],null]],null]],[12],[2,\"\\n\"],[6,[37,9],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[\"icon \",[34,6]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,9],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"\"],[12],[1,[34,5]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,9],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"selector\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,9],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dropdown-content\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"route\",\"text\",\"icon\",\"onSelect\"],[[32,1,[\"id\"]],[32,1,[\"text\"]],[32,1,[\"icon\"]],[30,[36,0],[[32,0],\"onSelectedOption\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"navigation-btn\",\"options\",\"-track-array\",\"each\",\"text\",\"icon\",\"id\",\"route\",\"if\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/navigation-btn.hbs"
    }
  });

  _exports.default = _default;
});