define("mgw/templates/components/select-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ihyORXZA",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[10,\"div\"],[14,0,\"overlay\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"modal modal--letter modal--adjusted-letter\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"bar bar--light flex space-between bar--nav paper-shadow\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bar__btn\"],[24,4,\"button\"],[4,[38,3],[[32,0],\"cancel\"],null],[12],[1,[30,[36,2],[\"cancel\"],null]],[13],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"bar__title\"],[12],[1,[30,[36,2],[\"selectRole\"],null]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bar__btn\"],[4,[38,3],[[32,0],\"submit\"],null],[12],[1,[30,[36,2],[\"ok\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"modal__body flex column flex-grow\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,1,\"form__row__title\"],[14,0,\"form__row form__row--big\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"form__label flex-grow\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"flex-shrink-0\"],[12],[1,[30,[36,2],[\"role\"],null]],[2,\":\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"select\"],[14,0,\"margin-right-11\"],[15,\"onchange\",[30,[36,3],[[32,0],\"onChange\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"option\"],[15,2,[32,1,[\"id\"]]],[15,\"selected\",[30,[36,1],[[32,1,[\"id\"]],[35,0]],null]],[12],[1,[30,[36,2],[[32,1,[\"text\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"selectedRole\",\"eq\",\"t\",\"action\",\"roleOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/select-role.hbs"
    }
  });

  _exports.default = _default;
});