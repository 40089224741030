define("mgw/controllers/users", ["exports", "mgw/misc/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    popoverservice: Ember.inject.service(),
    searchStr: '',
    searchProjectsStr: '',
    data: null,
    dataMap: null,
    columns: [],
    cache: null,
    selectedUsers: [],
    uniqueProjects: [],
    columnSettingsKey: Ember.computed('currentUser.activeCustomer', function () {
      return 'usersGridColumns-' + this.get('currentUser.activeCustomer.id');
    }),
    modelObserver: Ember.observer('model', function () {
      var _this$get$members,
          _this = this,
          _this$get$users;

      // Generate data structure to efficiently get the user privilege level on a specific project
      var projectRightsPerUser = {};
      var uniqueProjects = {};
      var projectsArray = [];
      (_this$get$members = this.get('model').members) === null || _this$get$members === void 0 ? void 0 : _this$get$members.forEach(function (projMember) {
        var userId = projMember.get('user.id');
        var projectId = projMember.get('project.id'); // Projects endpoint sometimes does not include a project from a customer member related....

        if (!projectId || !_this.store.peekRecord('project', projectId)) return;

        if (!projectRightsPerUser[userId]) {
          projectRightsPerUser[userId] = {
            user: projMember.get('user'),
            projects: {}
          };
        }

        projectRightsPerUser[userId].projects[projectId] = projMember; // Also we generate the array of unique projects related to the customer

        if (!uniqueProjects[projectId]) {
          uniqueProjects[projectId] = true;
          projectsArray.push(projMember.get('project'));
        }
      });
      projectsArray.sort(function (a, b) {
        return a.get('name').localeCompare(b.get('name'));
      });
      this.set('dataMap', projectRightsPerUser);
      this.set('uniqueProjects', projectsArray); // Time to generate the data to be consumed by the slickgrid (basically the array version)

      var data = [];
      (_this$get$users = this.get('model').users) === null || _this$get$users === void 0 ? void 0 : _this$get$users.forEach(function (user) {
        var _this$get;

        var customerAdmin = (_this$get = _this.get('currentUser.activeCustomer.customerAdmins')) === null || _this$get === void 0 ? void 0 : _this$get.includes(user.get('id'));
        var userRights = projectRightsPerUser[user.get('id')];
        if (!userRights && !customerAdmin) return;
        var item = {
          id: user.get('id'),
          firstName: user.get('firstName'),
          lastName: user.get('lastName'),
          organization: user.get('organization'),
          record: user
        };
        projectsArray.forEach(function (project) {
          if (customerAdmin) {
            // If customer admin, we just fake this role across all projects, as backend does consider it like that
            // (even if the user has other project-member with different privilege level)
            item[project.get('id')] = Ember.Object.create({
              privilegeLevel: 25
            });
          } else {
            item[project.get('id')] = userRights.projects[project.get('id')];
          }
        });
        data.push(item);
      });
      this.set('data', data); // Create columns info based on the data

      var columns = [{
        id: "id",
        field: "id",
        name: this.intl.t('email'),
        width: 250,
        sortable: true
      }, {
        id: "firstName",
        field: "firstName",
        name: this.intl.t('firstName'),
        width: 150,
        sortable: true
      }, {
        id: "lastName",
        field: "lastName",
        name: this.intl.t('lastName'),
        width: 150,
        sortable: true
      }, {
        id: "organization",
        field: "organization",
        name: this.intl.t('organization'),
        width: 150,
        sortable: true
      }];
      projectsArray.forEach(function (project) {
        columns.push({
          id: project.get('id'),
          field: project.get('id'),
          name: project.get('name'),
          formatter: _this.privilegeLevelFormatter.bind(_this),
          sortable: true,
          type: 'project'
        });
      });
      this.set('columns', columns);
    }),
    privilegeLevelFormatter: function privilegeLevelFormatter(row, cell, value, columnDef, dataContext) {
      if (!value) return '';
      var id = "row".concat(row, "cell").concat(cell);
      var extraInfo = '';

      if (value.get('privilegeLevel') == 5) {
        // guest
        var expirationDate = window.moment(dataContext.record.expirationDate).format('YYYY-MM-DD');
        extraInfo = '(exp. ' + expirationDate + ')';
      }

      if (value.get('ssoProviders.length')) {
        extraInfo = extraInfo + '(';
        value.get('ssoProviders').forEach(function (provider, i) {
          extraInfo = extraInfo + (i > 0 ? ', ' : '') + provider;
        });
        extraInfo = extraInfo + ')';
      }

      return "<span id=".concat(id, " class=\"privilege-btn privilege-").concat(value.get('privilegeLevel'), "\">").concat(this.intl.t(_utils.default.getPrivilegeLevelKeyText(value.get('privilegeLevel'))), " ").concat(extraInfo, "</span>");
    },
    actions: {
      searchChanged: function searchChanged(value) {
        this.set('searchStr', value);
      },
      searchProjectsChanged: function searchProjectsChanged(value) {
        this.set('searchProjectsStr', value);
      },
      didSelectItems: function didSelectItems(items) {
        this.set('selectedUsers', (items || []).map(function (item) {
          return item.record;
        }));
      },
      editSelected: function editSelected(sender) {
        var _this$get2;

        var disableDelete = this.get('selectedUsers.length') !== 1 || ((_this$get2 = this.get('currentUser.activeCustomer.customerAdmins')) === null || _this$get2 === void 0 ? void 0 : _this$get2.includes(this.get('selectedUsers.0.id')));
        var items = [];
        items.addObject({
          name: this.intl.t('setRoleForAllProjects'),
          value: 'selectRole'
        }); //items.addObject({ name: this.intl.t('setOrganization'), value: 'setOrganization'});

        items.addObject({
          name: this.intl.t('removeFromAllProjects'),
          value: 'removeSelectedFromProjects',
          color: 'red'
        });
        items.addObject({
          name: this.intl.t('deleteUser'),
          value: 'deleteSelected',
          color: 'red',
          disabled: disableDelete
        });
        this.get('popoverservice').openPopover({
          name: 'select-item',
          data: items,
          callback: 'selectedEditMenuOption',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: sender
        });
        return false;
      },
      selectedEditMenuOption: function selectedEditMenuOption(value) {
        this.send(value, this.get('selectedUsers'));
        return false;
      },
      selectRole: function selectRole() {
        this.get('popoverservice').openPopover({
          name: 'select-role',
          delegate: this,
          callback: 'setRoleToSelected',
          popoverClass: 'popover--floating'
        });
      },
      setRoleToSelected: function setRoleToSelected(privilegeLevel) {
        var _this$get3,
            _this2 = this;

        var promises = []; // We will get an array of all project members that needs to be updated

        (_this$get3 = this.get('selectedUsers')) === null || _this$get3 === void 0 ? void 0 : _this$get3.forEach(function (user) {
          var _this2$get;

          var customerAdmin = (_this2$get = _this2.get('currentUser.activeCustomer.customerAdmins')) === null || _this2$get === void 0 ? void 0 : _this2$get.includes(user.get('id'));
          if (customerAdmin) return; // We dont change privilege level for customer admins

          var userMembers = _this2.get('model.members').filterBy('user.id', user.get('id'));

          userMembers.forEach(function (member) {
            member.set('privilegeLevel', privilegeLevel);
            promises.push(member.save());
          });
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          _this2.set('cache', new Date());
        });
      },
      setOrganization: function setOrganization(users) {
        // To be implemented
        return false;
      },
      removeSelectedFromProjects: function removeSelectedFromProjects() {
        this.send('setRoleToSelected', 0);
        return false;
      },
      deleteSelected: function deleteSelected() {
        var _this$get4;

        if (!this.get('selectedUsers.length')) return; // We dont delete customer admins

        var customerAdmin = (_this$get4 = this.get('currentUser.activeCustomer.customerAdmins')) === null || _this$get4 === void 0 ? void 0 : _this$get4.includes(this.get('selectedUsers.0.id'));
        if (customerAdmin) return;
        this.get('popoverservice').openPopover({
          name: 'delete-user-info',
          data: this.get('selectedUsers.0'),
          popoverClass: 'popover--floating'
        });
      },
      addUser: function addUser() {
        // To be implemented
        return false;
      },
      changeCustomer: function changeCustomer(customer) {
        this.get('currentUser').changeActiveCustomer(customer);
        this.send('reload');
        return false;
      },
      refreshCache: function refreshCache() {
        this.set('cache', new Date());
      }
    }
  });

  _exports.default = _default;
});