define("mgw/components/customer-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['customer-select'],
    //input
    allowAll: false,
    onChange: null,
    customers: Ember.computed.alias('currentUser.customerAdminFor'),
    activeCustomer: Ember.computed.alias('currentUser.activeCustomer'),
    actions: {
      onSelectedCustomer: function onSelectedCustomer(customerId) {
        this.currentUser.changeActiveCustomer(customerId);

        if (this.onChange) {
          this.onChange(customerId);
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});