define("mgw/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: Ember.computed.alias('id'),
    guest: _emberData.default.attr('boolean', {
      readOnly: true
    }),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    organization: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    timeZone: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    expirationDate: _emberData.default.attr('date'),
    allowedProviders: _emberData.default.attr(),
    userOrganization: _emberData.default.belongsTo('userOrganization', {
      inverse: null
    })
  });

  _exports.default = _default;
});