define("mgw/components/navigation-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var navigationInfo = {
    'users': {
      textKey: 'users',
      icon: 'icon--user'
    },
    'dashboard': {
      textKey: 'enterpriseTools',
      icon: 'icon--configs'
    },
    'project-widgets': {
      textKey: 'projectWidgets',
      icon: 'icon--dashboard'
    },
    'projects-list': {
      textKey: 'projectsList',
      icon: 'icon--list'
    },
    'project-templates': {
      textKey: 'projectTemplates',
      icon: 'icon--pencil'
    }
  };

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    deviceservice: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['page-header', 'space-between'],
    controller: null,
    chrome: window.bowser.chrome,
    accountTitle: Ember.computed('currentUser', 'intl', 'deviceservice.mobile', function () {
      if (this.get('deviceservice.mobile')) return "";
      return this.get('currentUser.user.displayName') || this.get('currentUser.user.email') || this.intl.t('account');
    }),
    supportedBrowser: Ember.computed.alias('chrome'),
    customerAdmin: Ember.computed.alias('currentUser.customerAdminFor.length'),
    showEnterpriseTools: Ember.computed('customerAdmin', 'supportedBrowser', 'deviceservice.mobile', function () {
      return this.get('customerAdmin') && this.get('supportedBrowser') && !this.get('deviceservice.mobile');
    }),
    title: Ember.computed('router.currentRouteName', 'intl', function () {
      if (navigationInfo[this.get('router.currentRouteName')]) {
        return this.intl.t(navigationInfo[this.get('router.currentRouteName')].textKey);
      } else {
        return null;
      }
    }),
    icon: Ember.computed('router.currentRouteName', 'intl', function () {
      if (navigationInfo[this.get('router.currentRouteName')]) {
        return navigationInfo[this.get('router.currentRouteName')].icon;
      } else {
        return null;
      }
    }),
    enterpriseOptions: Ember.computed('intl', function () {
      return [{
        id: 'project-widgets',
        text: this.intl.t('projectWidgets'),
        icon: 'icon--dashboard'
      }, {
        id: 'projects-list',
        text: this.intl.t('projectsList'),
        icon: 'icon--list'
      }, {
        id: 'project-templates',
        text: this.intl.t('projectTemplates'),
        icon: 'icon--pencil'
      }, {
        id: 'users',
        text: this.intl.t('users'),
        icon: 'icon--user'
      }];
    }),
    accountOptions: Ember.computed('intl', function () {
      return [{
        id: 'user-profile',
        text: this.intl.t('editProfile'),
        icon: 'icon--pencil'
      }, {
        id: 'logout',
        text: this.intl.t('logout'),
        icon: 'icon--logout'
      }];
    }),
    actions: {
      goToRoute: function goToRoute(route) {
        switch (route) {
          case 'help':
            this.controller.send('help');
            break;

          case 'logout':
            this.controller.send('logout');
            break;

          default:
            this.controller.transitionToRoute(route);
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});